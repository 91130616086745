// Resume detail interactions ************************************************************************************
// ************************************************************************************************
import {
  useSwrMutationApi,
} from 'src/hooks/useSwrApi';


interface UseSwrProfileResumeTrackingProps {
  successCallback?: () => void;
}

export const useSwrProfileResumeTracking = (props: UseSwrProfileResumeTrackingProps) => {
  const {
    successCallback,
  } = props;

  const url = process.env.NEXT_PUBLIC_TRACKING_RESUME_DETAIL as string;

  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<undefined, string>({
    url,
    fetcherConfig: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    apiOptions: {
      onSuccess: () => {
        if (successCallback) {
          void successCallback();
        }
      },
    },
  });

  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
