'use client';

import {
  isValidElement,
  ReactElement,
  useCallback, useEffect, useState,
} from 'react';
import {
  CvDetailExperiencePlaceholder,
  CvDetailProfileHeadingPlaceholder,
  CvDetailSkillsPlaceholder,
} from '@components/placeholders/cv';
import { ProfileOperations, ProfileResumeSections } from '@components/templates/cv/sections';
import {
  CvDetailAvailableOperationsCurrentStatus,
  CvDetailObject,
} from '@components/templates/cv/interface/CvDetailInterface';
import { BlurredCvDetail } from '@components/templates/cv/blurred/BlurredCvDetail';
import { ProfileSimilarWrapper } from '@components/templates/cv/sections/ProfileSimilarWrapper';
import { useCvDetails } from '@components/templates/cv/hooks';

// Redux
import { useAppSelector } from 'src/app';
import { getUserHeaderInfo } from '@store/reducers/userSelector';
import { useExtendedRouter } from '@hooks/next-routing-wrappers';
import { useSearchParams } from 'next/navigation';
import useResumeTracking from '@hooks/tracking/useResumeTracking';
import { TopAnchorLinks } from '@components/templates/cv/sections/TopAnchorLinks';
import { useAuthClient } from '@hooks/common/useAuthClient/useAuthClient';

// Interface
// ************************************
export interface CvDetailHubProps {
  slug?: string,
  jobSlug?: string,
  isAuthenticated?: boolean,
  isSinglePage?: boolean,
  closeModal?: () => void,
  statusChangeFromModal?:(statusId: string, slug:string)=>void;
  queryParams?: string;
  withNavbarInModal?: boolean;
  closeControl?: ReactElement | undefined
}


/**
 * @description CV Detail Sections Hub. Used for rendering a CV regardless of context (job, search, own).
  * - **slug** - string - The CV slug, optional.
 * - **jobSlug** - string - The job slug, optional.
 * - **isAuthenticated** - boolean - If the user is authenticated, optional.
 * - **closeModal** - function - Function to close the modal (if viewing in a modal), optional.
 * - **isSinglePage** - boolean- If the user is on the single page instead of modal
 * - **queryParams** - string - The querystring taken from the current URL, optional.
 * - **withNavbarInModal** - boolean - If the header navbar is included in modal, optional.
 */
export const CvDetailHub = (props: CvDetailHubProps) => {
  // Destructure props
  const {
    slug,
    jobSlug,
    isSinglePage,
    isAuthenticated,
    closeModal,
    statusChangeFromModal,
    queryParams,
    withNavbarInModal,
    closeControl,
  } = props;


  // Get locale from router
  // ************************************
  const { locale } = useExtendedRouter();

  // Get the url search params and get the rid
  // ************************************
  const searchParams = useSearchParams();
  const rid = searchParams?.get('rid') || '';

  // Common hooks
  // ************************************
  const trackResume = useResumeTracking();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const eventChannel = urlParams.get('ch_name');
    const jobId = urlParams.get('ch_id');
    const kw = urlParams.get('kw');
    const searchId = urlParams.get('search_id');
    const applicantId = urlParams.get('applicant_id');
    const resumeId = urlParams.get('resume_id');
    const userId = urlParams.get('user_id');
    const rId = urlParams.get('rid');
    const chId = urlParams.get('ch_id');

    if (eventChannel) {
      trackResume({
        ch_name: eventChannel,
        ...(jobId ? { job_id: Number(jobId) } : {}),
        ...(kw ? { kw } : {}),
        ...(userId ? { user_id: Number(userId) } : {}),
        ...(searchId ? { search_id: searchId } : {}),
        ...(chId ? { ch_id: chId } : {}),
        ...(resumeId ? { resume_id: resumeId } : {}),
        ...(applicantId ? { applicant_id: applicantId } : {}),
        ...(rId ? { request_id: rId } : {}),
      });
    }
  }, [trackResume]);

  // Get header info data for finding out the user's role
  // ************************************
  const headerInfoData = useAppSelector(getUserHeaderInfo);
  const userRole = headerInfoData?.role;

  // Whether the CV detail is being viewed by a COMPANY.
  // ************************************
  const isCompany = !!headerInfoData;

  // Get CV details
  // ************************************
  const {
    cvDetail,
    showBlurredCv,
    isCvLoaded,
    setCvLoaded,
    phoneHighlight,
    setPhoneHighlight,
    availableResumeInformations,
    applicantSeenContactStatus,
    setJobFolderInfo,
    setApplicantSeenContactStatus,
    fetchResumeDetail,
    jobFolderInfo,
  } = useCvDetails({
    rid,
    slug,
    isCompany,
    toFetchOperationJob: !jobSlug,
    locale,
    ...(jobSlug && { jobSlug }),
  });

  // States
  // ************************************
  const [currentCVStatus, setCurrentCVStatus] = useState<CvDetailAvailableOperationsCurrentStatus>();
  const [jobFolderHighlight, setJobFolderHighlight] = useState<boolean>(false);

  const { isAuth } = useAuthClient();

  // Helper functions
  // ************************************
  const getCurrentCvStatus = useCallback((item:CvDetailAvailableOperationsCurrentStatus) => setCurrentCVStatus(item), []);
  const applicantSeenContact = () => setApplicantSeenContactStatus(true);
  const setTelephoneHighlight = () => setPhoneHighlight(true);
  const setCurrentJobFolderHighlight = () => setJobFolderHighlight(true);
  const setLoadingCvStatus = () => setCvLoaded(false);

  // Don't render if there is no slug
  // ************************************
  if (!slug) return null;

  // Render component
  // ************************************
  return (
    <section className="grow">

      { /* Show it only after the CV is loaded and user has acces to it. */
        isCvLoaded && !showBlurredCv && isAuth && (
          <ProfileOperations
            applicantSeenContactStatus={applicantSeenContactStatus}
            applicantSeenContact={applicantSeenContact}
            setLoadingCvStatus={setLoadingCvStatus}
            setCurrentJobFolderHighlight={setCurrentJobFolderHighlight}
            setTelephoneHighlight={setTelephoneHighlight}
            isFromJobContext={!!jobSlug}
            jobFolderId={jobSlug ? 0 : jobFolderInfo?.jobId ?? 0}
            jobSlug={jobSlug || jobFolderInfo?.jobSlug}
            jobTitle={jobSlug ? '' : jobFolderInfo?.title ?? ''}
            reFetchData={fetchResumeDetail}
            sendCurrentCvStatus={getCurrentCvStatus}
            cvDetail={cvDetail as CvDetailObject}
            slug={slug}
            closeCvModal={closeModal}
            statusChangeFromModal={statusChangeFromModal}
            isCompany={isCompany}
            rid={rid}
            withNavbarInModal={withNavbarInModal}
            isSinglePage={isSinglePage}
            locale={locale}
          />
        )
      }

      { isValidElement(closeControl) && closeControl }

      <div className="pb-20">
        { !isCvLoaded && (
        <>
          <div className="border-b bg-surface-100 lg:block">
            <div className="container flex items-center justify-between py-2">
              <div className="flex items-center">
                <div className="mx-auto mr-4 size-6 rounded-full bg-slate-200 md:size-8" />
                <div className="mx-auto mr-4 size-6 rounded-full bg-slate-200 md:size-8" />
                <div className="mx-auto size-6 rounded-full bg-slate-200 md:size-8" />
              </div>

              <div className="flex items-center">
                <div className="mx-auto ml-4 size-6 rounded-full bg-slate-200 md:size-8" />
                <div className="mx-auto ml-4 mr-2 size-6 rounded-full bg-slate-200 md:mr-0 md:size-8" />

              </div>
            </div>

            <div className="container mx-auto block min-h-screen grid-cols-10 gap-4 px-0 lg:grid lg:px-4">
              <div
                className="relative col-span-3 h-screen border-0 bg-surface p-5 px-2 lg:min-h-full lg:border-r lg:p-0 lg:pb-24 lg:pr-2"
              >
                <CvDetailProfileHeadingPlaceholder />
              </div>
              <div className="col-span-4 hidden lg:block">
                <CvDetailExperiencePlaceholder />
                <CvDetailSkillsPlaceholder />
                <CvDetailExperiencePlaceholder />
              </div>
              <div className="col-span-3 hidden border-l bg-surface px-4 lg:block">
                <CvDetailExperiencePlaceholder />
              </div>
            </div>
          </div>
        </>
        )}


        <div className="mb-10 bg-surface">
          {isCvLoaded
          && <TopAnchorLinks cvDetail={cvDetail} />}

          { /* Show the profile sections if data available and user has access */
            isCvLoaded && !showBlurredCv && (
              <div className="container mx-auto">
                <ProfileResumeSections
                  cvDetail={cvDetail as CvDetailObject}
                  applicantSeenContactStatus={applicantSeenContactStatus}
                  cvCurrentJobUnlockFolder={jobFolderInfo}
                  setJobFolderInfo={setJobFolderInfo}
                  phoneHighlight={phoneHighlight}
                  jobFolderHighlight={jobFolderHighlight}
                  currentCVStatus={currentCVStatus as CvDetailAvailableOperationsCurrentStatus}
                  {...(jobSlug && { jobFolderSlug: jobSlug, jobSlug })}
                  jobFolderContext={!jobSlug}
                  queryParams={queryParams}
                  isCompany={isCompany}
                  locale={locale}
                />
              </div>
            )
          }
        </div>

        <div className="bg-surface-100 py-10">
          { /* Show the profile sections if data available and user has access */
            isCvLoaded && !showBlurredCv && (
              <div className="container mx-auto">
                <ProfileSimilarWrapper
                  cvDetail={cvDetail as CvDetailObject}
                  jobSlug={jobSlug || jobFolderInfo?.jobSlug}
                  locale={locale}
                />
              </div>
            )
          }
        </div>


      </div>

      { /* Show the blurred CV sections if data available and no access */
          isCvLoaded && showBlurredCv && (
            <BlurredCvDetail
              setLoadingCvStatus={setLoadingCvStatus}
              isFromJobContext={!!jobSlug}
              slug={slug}
              jobSlug={jobSlug || jobFolderInfo?.jobSlug}
              availableResumeInformations={availableResumeInformations as CvDetailObject}
              setUnlockFromBlurred={fetchResumeDetail}
              closeCvModal={closeModal}
              isAuthenticated={isAuthenticated}
              userRole={userRole}
              locale={locale}
            />
          )
        }
    </section>
  );
};
