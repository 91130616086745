import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  ProfileSimilarCandidates,
} from '@components/templates/cv/sections/index';
import {
  ApiSimilarCandidate,
  ApiUserTalentItem,
  fetcher,
  isSimilarCandidateStatusOk,
} from '@utils/data-fetching';
import { CvDetailObject } from '../interface/CvDetailInterface';

export const ProfileSimilarWrapper = ({
  cvDetail,
  locale,
  jobSlug,
}: {
  cvDetail: CvDetailObject
  locale: Locale,
  jobSlug?: string,
}) => {
  const slug = cvDetail?.slug;
  const [candidates, setCandidates] = useState<ApiUserTalentItem[]>([]);

  const [isSimilarCandidatesLoaded, setIsSimilarCandidatesLoaded] = useState<boolean>(false);

  const fetchSimilarCandidates = useCallback(() => {
    fetcher<ApiSimilarCandidate>(`/v1/${slug}/similar-users${jobSlug ? `?jobSlug=${jobSlug}` : ''}`, locale, {
      method: 'GET',
    })
      .then((response) => {
        if (isSimilarCandidateStatusOk(response)) {
          setCandidates(response as []);
          setIsSimilarCandidatesLoaded(true);
        }
      })
      .catch(() => {
      });
  }, [jobSlug, locale, slug]);

  useEffect(() => {
    fetchSimilarCandidates();
  }, [fetchSimilarCandidates]);

  return (
    <>
      { isSimilarCandidatesLoaded && !cvDetail?.isOwnCV && (
      <ProfileSimilarCandidates
        candidates={candidates}
        userSlug={slug}
        locale={locale}
      />
      )}
    </>
  );
};
