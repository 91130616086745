import { ApiUserRole } from '@type/v1-api-types/user';
import { useTranslation } from 'next-i18next';
import { ResumeArrowsNavigation } from '@components/templates/cv/partials/navigation/ResumeArrowsNavigation';
import { useCallback, useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Button, Tooltip } from '@components/common';
import { CvDetailObject } from '@components/templates/cv/interface/CvDetailInterface';
import { ApiUserTalentItem, fetcher } from '@utils/data-fetching';
import { BlurredCvContent } from '@components/templates/cv/blurred/BlurredCvContent';

interface IBlurredModal {
  slug : string;
  jobSlug: string;
  isFromJobContext?: boolean;
  availableResumeInformations: CvDetailObject;
  setLoadingCvStatus: () => void;
  setUnlockFromBlurred:() => void;
  closeCvModal?: () => void,
  isAuthenticated?: boolean,
  userRole?: ApiUserRole,
  locale: Locale,
}

export const BlurredCvDetail = (props:IBlurredModal) => {
  const {
    slug,
    jobSlug,
    availableResumeInformations,
    isFromJobContext,
    setLoadingCvStatus,
    setUnlockFromBlurred,
    closeCvModal,
    isAuthenticated,
    userRole,
    locale,
  } = props;

  const { t } = useTranslation('common');
  const [candidates, setCandidates] = useState<ApiUserTalentItem[]>([]);

  const fetchSimilarCandidates = useCallback(() => {
    fetcher(`/v1/${slug}/similar-users`, locale, {
      method: 'GET',
    }).then((response) => {
      setCandidates(response as []);
    }).catch(() => {});
  }, [locale, slug]);

  useEffect(() => {
    fetchSimilarCandidates();
  }, [fetchSimilarCandidates]);

  const setLoadingCvStatusFromOperation = () => {
    setLoadingCvStatus();
  };

  return (
    <>
      <section className="grow bg-surface md:bg-surface-50">
        <div className={`border-bottom h-16 border bg-surface-100 py-2 md:h-20 md:py-4${closeCvModal ? ' sticky top-0 z-1 self-start' : ''}`}>
          <div className="container mx-auto">
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center gap-4">

                { /* Close modal button */
                  closeCvModal && (
                    <Tooltip tooltipText={t('global.btn.close')} position="bottom" colorScheme="default">
                      <Button styling="none" onClick={closeCvModal} aria-label={t('global.btn.close')}>
                        <span className="flex size-7 items-center justify-center rounded-full bg-surface md:size-8">
                          <XMarkIcon className="size-5 text-ink" />
                        </span>
                      </Button>
                    </Tooltip>
                  )
                }

              </div>

              <ResumeArrowsNavigation
                setLoadingCvStatusFromOperation={setLoadingCvStatusFromOperation}
                slug={slug}
                jobSlug={jobSlug}
                isFromJobContext={isFromJobContext}
              />

            </div>
          </div>

        </div>

        <BlurredCvContent
          candidates={candidates}
          availableResumeInformations={availableResumeInformations}
          slug={slug}
          jobSlug={jobSlug}
          setUnlockFromBlurred={() => setUnlockFromBlurred()}
          isAuthenticated={isAuthenticated}
          userRole={userRole}
          locale={locale}
        />
      </section>
    </>
  );
};
