import { useTranslation } from 'next-i18next';
import { CvDetailInterface } from '@components/templates/cv/interface/CvDetailInterface';
import Link from 'next/link';

/**
 * @description Component to render the top link anchors to navigate to different sections of the CV.
 * @param cvDetail - the cv detail object
 */
export const TopAnchorLinks = ({ cvDetail }: CvDetailInterface) => {
  // Translation
  const { t } = useTranslation('common');


  // Render component
  // ******************************************************
  return (
    <section className="w-full min-w-full  gap-5 overflow-x-scroll border-b bg-surface-50 py-3 pr-5 lg:hidden">

      <div className="container mx-auto">
        <div className="item-scenter flex gap-5">
          <Link
            href="#about-me"
            className="block min-w-max cursor-pointer text-sm font-normal text-primary hover:underline lg:hidden"
          >
            {t('cv.detail.topAnchors.who-am-i')}
          </Link>
          {cvDetail?.experiences && (
            <Link
              href="#experience"
              className="block min-w-max cursor-pointer text-sm font-normal text-primary hover:underline lg:hidden"
            >
              {t('cv.detail.topAnchors.experience')}
            </Link>
          )}

          {cvDetail?.application?.evaluation && (
            <Link
              href="#evaluateBia"
              className="min-w-max cursor-pointer text-sm font-normal text-primary hover:underline"
            >
              {t('cv.detail.topAnchors.evaluation')}
            </Link>
          )}
          {!cvDetail?.isOwnCV && (
            <Link
              href="#similarCandidates"
              className="min-w-max cursor-pointer text-sm font-normal text-primary hover:underline"
            >
              {t('label-cv-tab-similar')}
            </Link>
          )}
        </div>
      </div>
    </section>

  );
};
