import { useCallback, useEffect } from 'react';
import { useSwrProfileResumeTracking } from '@hooks/useSwrApi/endpoints/tracking/useSwrProfileResumeTracking';

interface TrackingCvDetail {
  event_id?: number | string;
  event_type: string;
  event_channel: string;
  created_at: string;
  context?: {
    resume_id?: string;
    applicant_id?:string;
  }
}

const useResumeTracking = () => {
  const onSuccessCallback = () => {
    sessionStorage.removeItem('resumeTrackingData');
  };

  const {
    trigger: triggerSendData,
  } = useSwrProfileResumeTracking({
    successCallback: onSuccessCallback,
  });

  const getStoredEvents = (): TrackingCvDetail[] => {
    const storedEvents = sessionStorage.getItem('resumeTrackingData');
    return (storedEvents ? JSON.parse(storedEvents) : []) as TrackingCvDetail[];
  };

  const sendResumeTrackingData = useCallback(() => {
    const storedEvents = getStoredEvents();
    if (storedEvents.length > 0) {
      const shouldSendData = storedEvents.length > 10 || document.visibilityState === 'hidden';

      if (shouldSendData) {
        const payload = JSON.stringify(storedEvents);
        void triggerSendData(payload);
        onSuccessCallback();
      }
    }
  }, [triggerSendData]);

  const trackResume = useCallback((trackingData: {
    resume_id?:string,
    applicant_id?:string,
    request_id?:string,
    ch_name: string,
    user_id?:number,
    job_id?: number,
    kw?:string,
    ch_id?:string,
    search_id?:string }) => {
    if (trackingData) {
      const context = trackingData.resume_id || trackingData.applicant_id
        ? {
          ...(trackingData.resume_id && { resume_id: trackingData.resume_id }),
          ...(trackingData.applicant_id && { applicant_id: trackingData.applicant_id }),
        }
        : undefined;

      const event: TrackingCvDetail = {
        event_id:
          trackingData.job_id?.toString()
          || trackingData.kw
          || trackingData.search_id,
        event_type: 'cv_detail',
        event_channel: trackingData.ch_name,
        created_at: new Date().toISOString(),
        ...(trackingData.job_id && { job_id: trackingData.job_id.toString() }),
        ...(trackingData.kw && { kw: trackingData.kw }),
        ...(trackingData.ch_id && { kw: trackingData.ch_id }),
        ...(trackingData.user_id && { user_id: trackingData.user_id }),
        ...(trackingData.search_id && { search_id: trackingData.search_id }),
        ...(trackingData.request_id && { request_id: trackingData.request_id }),
        ...(context && { context }),
      };

      const storedEvents = getStoredEvents();
      const updatedEvents = [...storedEvents, event];
      sessionStorage.setItem('resumeTrackingData', JSON.stringify(updatedEvents));
      sendResumeTrackingData();
    }
  }, [sendResumeTrackingData]);


  useEffect(() => {
    sendResumeTrackingData();
    document.addEventListener('visibilitychange', sendResumeTrackingData);

    return () => {
      document.removeEventListener('visibilitychange', sendResumeTrackingData);
    };
  }, [sendResumeTrackingData]);

  return trackResume;
};

export default useResumeTracking;
